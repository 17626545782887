import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import Avatar from "./Avatar/Avatar";
import StudentGroupHeader from "./StudentGroupHeader";
import { useTranslation } from "react-i18next";
import FooterTools from "./FooterTools/FooterTools";
import { useDispatch, useSelector } from "react-redux";
import { setStudents } from "../features/students/studentsSlice";
import HalloweenTheme from "./Themes/Halloween";
import firebaseApp from "../credentials";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { correctToast } from "../Components/ToastUtils";
import Business from "../data/Business";
import {
  migratePetRewardData,
  updateLastPointsAddedDate,
} from "../features/settings/settingsThunks";

const firestore = getFirestore(firebaseApp);

const RenderAvatars = ({ correoUsuario }) => {
  const { t } = useTranslation();

  const [originalOrder, setOriginalOrder] = useState([]);
  const [sortedOrder, setSortedOrder] = useState([]);

  const [arrayStudents, setArrayStudents] = useState(null);
  const [ordenAlfabetico, setOrdenAlfabetico] = useState(false);

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.auth.userEmail);
  const students = useSelector((state) => state.students) || [];
  const petReward = useSelector((state) => state.settings.petReward);
  const accountType = useSelector((state) => state.settings.accountType);

  const lastPointsAddedDateStr = useSelector(
    (state) => state.settings.lastPointsAddedDate
  );
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const toggleOrdenAlfabetico = () => {
    setOrdenAlfabetico((prevOrden) => !prevOrden);
  };

  // MIGRATION code, to check if there's an user option saved on old localstorage
  useEffect(() => {
    dispatch(migratePetRewardData(userEmail));
  }, [dispatch]);

  useEffect(() => {
    const today = new Date();
    const todayDate = today.toISOString().split("T")[0]; // Formato ISO 8601 (yyyy-mm-dd)

    const lastPointsAddedDateStrISO = formatDateToISO(lastPointsAddedDateStr);
    const lastPointsAddedDate = new Date(lastPointsAddedDateStrISO);

    const differenceInTime = today.getTime() - lastPointsAddedDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // Comprobar si hay estudiantes con negocios
    const hasBusinesses = students.some(
      (student) => student.businesses && student.businesses.length > 0
    );

    // Mostrar el botón si han pasado 7 días o más y no se han añadido puntos todavía,
    // y si petReward está activado o si hay estudiantes con negocios
    if (differenceInDays >= 7) {
      const hasBusinesses = students.some(
        (student) => student.businesses && student.businesses.length > 0
      );

      setShowButton(petReward || hasBusinesses);
    } else {
      setShowButton(false);
    }
  }, [students]);

  useEffect(() => {
    async function fetchStudents() {
      try {
        if (students && Array.isArray(students)) {
          const fetchedStudents = students;
          const sortedStudents = [...fetchedStudents].sort((a, b) =>
            a.surname.localeCompare(b.surname)
          );
          setOriginalOrder(fetchedStudents);

          setSortedOrder(sortedStudents);

          if (localStorage.getItem("isSorted") === "true") {
            setArrayStudents(sortedStudents);
          } else {
            setArrayStudents(fetchedStudents);
          }
        }
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    }
    fetchStudents();
  }, [students]);

  function formatDateToISO(dateStr) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
      return dateStr;
    }
    if (dateStr) {
      const dateComponents = dateStr.split("/");

      if (dateComponents.length === 3) {
        const day = dateComponents[0];
        const month = dateComponents[1];
        const year = dateComponents[2];

        // Formatea la fecha en el formato ISO 8601 (yyyy-mm-dd)
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
      } else {
        // Si la cadena de fecha no tiene el formato esperado, devuelve null o una cadena de error
        return null;
      }
    } else {
      // Maneja el caso en el que dateStr sea nulo
      return null;
    }
  }

  const toggleOrder = () => {
    if (localStorage.getItem("isSorted") === "true") {
      setArrayStudents(originalOrder);
      localStorage.setItem("isSorted", "false");
    } else {
      // Filtrar al estudiante con id 0
      const studentId0 = originalOrder.find((student) => student.id === 0);
      const restOfStudents = originalOrder.filter(
        (student) => student.id !== 0
      );

      // Ordenar el resto de estudiantes
      const sortedStudents = restOfStudents.sort((a, b) =>
        a.surname.localeCompare(b.surname)
      );

      // Agregar al estudiante con id 0 al principio de la lista ordenada
      setArrayStudents([studentId0, ...sortedStudents]);
      localStorage.setItem("isSorted", "true");
    }
  };

  const onUpdateAll = async () => {
    const fetchedStudents = students;
    setArrayStudents(fetchedStudents);
  };

  //Fn created to re-render when selected students are updated.
  //Basically it goes as a prop to FooterTools and to MultiSelectPopup
  //When selected students are updated, this callback will go up till here
  //and force a re-rendering
  const handleStudentsUpdate = (updatedStudents) => {
    setArrayStudents(updatedStudents);
  };

  //fn to force re-rendering
  function updateReRender() {
    navigate("/game");
  }

  let isAddingPoints = false;

  async function addPointsPetBizHolder() {
    if (isAddingPoints) return; // Avoid calling the fn more than once
    isAddingPoints = true;
    const today = new Date();
    const todayDate = today.toLocaleDateString();
    const lastPointsAddedDate = new Date(lastPointsAddedDateStr);

    const actionName = t("petPoints");

    // Calcular la diferencia en días
    const differenceInTime = today.getTime() - lastPointsAddedDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays >= 7) {
      let updates = {};

      // Copia de la lista de estudiantes para modificar
      const updatedStudents = students.map((student) => ({ ...student }));

      // Procesar las mascotas si petReward es true
      if (petReward) {
        const amount = 7;

        updatedStudents.forEach((student) => {
          if (student.pet !== "") {
            student.points += amount;

            // Crear registro de historial para mascotas
            const newHistoryEntry = {
              date: todayDate,
              amount: amount,
              type: "+",
              description: actionName,
            };

            student.records = [...(student.records || []), newHistoryEntry];
            updates[`students.${student.id}`] = { ...student };
          }
        });
      }

      // Procesar los negocios
      updatedStudents.forEach((student) => {
        if (student.businesses && student.businesses.length > 0) {
          let totalBusinessPoints = 0;

          student.businesses.forEach((businessId) => {
            const business = Business.find((b) => b.id === businessId);
            if (business) {
              const points = getRandomInt(
                business.minReturn,
                business.maxReturn
              );
              totalBusinessPoints += points;
            }
          });

          if (totalBusinessPoints > 0) {
            student.points += totalBusinessPoints;

            // Crear registro de historial para negocios
            const newBusinessHistoryEntry = {
              date: todayDate,
              amount: totalBusinessPoints,
              type: "+",
              description: t("businessPoints"),
            };

            student.records = [
              ...(student.records || []),
              newBusinessHistoryEntry,
            ];
            updates[`students.${student.id}`] = { ...student };
          }
        }
      });

      // Actualizar Redux y Firestore
      setArrayStudents(updatedStudents);
      const docuRef = doc(firestore, `usuarios/${userEmail}`);
      await updateDoc(docuRef, updates);
      await dispatch(setStudents(updatedStudents));

      // Añadir nueva fecha en firebase y mostrar el mensaje correspondiente
      const todayDateFormatted = today.toISOString().split("T")[0]; // Formato ISO 8601 (yyyy-mm-dd)
      await dispatch(updateLastPointsAddedDate(userEmail, todayDateFormatted));
      setShowButton(false);
      correctToast(t("alertPointsAdded"));
    }
    isAddingPoints = false;
  }

  // Función auxiliar para obtener un número aleatorio entre min y max
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Función auxiliar para obtener un número aleatorio entre min y max
  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const navigate = useNavigate();
  //If it's a new account without data on the DB, stay here until all initial data has been uploaded
  //Once the data has been uploaded, arrayStudents re-renders the useeffect and it redirects the user
  //to create classroom until there is more than 1 student
  useEffect(() => {
    if (loading) {
      return;
    }

    if (!Array.isArray(arrayStudents)) {
      return;
    }

    if (arrayStudents.length === 1) {
      return navigate("/chooseChibit");
    }
  }, [arrayStudents, loading]);

  const sortedStudents = arrayStudents
    ? [...arrayStudents].sort((a, b) => {
        const surnameA = a.surname.toLowerCase();
        const surnameB = b.surname.toLowerCase();
        if (ordenAlfabetico) {
          return surnameA.localeCompare(surnameB);
        } else {
          return 0; // Mantener el orden original
        }
      })
    : [];

  if (loading) {
    // return <div className="loader"></div>;
    return <div></div>;
  }

  return (
    <>
      <div className="App">
        <Header />
        <StudentGroupHeader />

        <div className="apps">
          {showButton && (
            <div className="popup">
              <div className="popup-overlay"></div>
              <div className="popup-content">
                <button
                  className="btn btn-delete center add-pet-popup-btn"
                  onClick={addPointsPetBizHolder}
                  disabled={isAddingPoints}
                >
                  {t("addPetPoints")}
                </button>
              </div>
            </div>
          )}
          <ul>
            {arrayStudents
              ? sortedStudents.map((data, index) => {
                  return (
                    <div key={data.id}>
                      <Avatar
                        key={index}
                        id={data.id}
                        name={data.name}
                        surname={data.surname}
                        img={data.img}
                        pet={data.pet}
                        correoUsuario={correoUsuario}
                        points={data.points}
                        onUpdateAll={onUpdateAll}
                      />
                    </div>
                  );
                })
              : null}
          </ul>
        </div>
        <div className="spacing"></div>
        <FooterTools
          toggleOrder={toggleOrder}
          students={arrayStudents}
          onStudentsUpdate={handleStudentsUpdate}
          accountType={accountType}
        />
      </div>
      <ToastContainer />
    </>
  );
};

export default RenderAvatars;
