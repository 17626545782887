import React from 'react'

function HalloweenTheme() {
  return (
        <div className="halloween">
          <img src="../../images/halloween/spider.png" className="spider" />
          <img
            src="../../images/halloween/pumpkin1.png"
            className="pumpkin01"
          />
          <img
            src="../../images/halloween/pumpkin2.png"
            className="pumpkin02"
          />
          <img src="../../images/halloween/spidernet.png" />
        </div>
  )
}

export default HalloweenTheme